<template>
  <div>
    <vx-card card-background="#021a33">
      <vs-row vs-type="flex">
        <vs-col vs-w="8" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
          <div>
            <h5 style="color: white">ROTAS: {{ rotas.length }}</h5>
          </div>
        </vs-col>
        <vs-col vs-w="4" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
          <div>
            <ModalCadastroRota @update="getRotas()" />
          </div>
        </vs-col>
      </vs-row>
    </vx-card>
    <VuePerfectScrollbar
      class="scroll-rota-lista"
      :settings="settings"
      style="background-color: #ebebeb"
    >
      <div id="rotaLista" class="vs-con-loading__container">
        <div v-if="!rotas.length > 0">
          <div class="con-colors mt-10 mb-20">
            <ul>
              <span class="px-3 pt-2" style="color: grey !important; font-weight: 1000 !important">
                <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
              </span>
            </ul>
          </div>
        </div>
        <vs-list v-for="(rota, idx) in rotas" v-bind:key="idx">
          <vx-card
            :class="
              blocked == false
                ? selectedRot !== rota.id
                  ? 'rota'
                  : 'active-rota'
                : selectedRot !== rota.id
                ? 'rota-disabled'
                : 'active-rota'
            "
            @click="
              selectTec(rota), blocked == false ? (selectedRot = rota.id) : 1
            "
          >
            <template slot="no-body">
              <div>
                <div class>
                  <vs-row vs-type="flex" vs-justify="center" vs-align="center">
                    <vs-col
                      class="pt-3 px-2"
                      vs-w="8"
                      vs-type="flex"
                      vs-justify="flex-start"
                      vs-align="flex-start"
                    >
                      <h4>
                        <b>{{ rota.nome }}</b>
                      </h4>
                    </vs-col>
                    <vs-col
                      class="pt-3"
                      vs-w="4"
                      vs-type="flex"
                      vs-justify="flex-end"
                      vs-align="flex-end"
                    >
                      <ModalEditarRota @update="getRotas()" class="right" :rota="rota" />
                    </vs-col>
                  </vs-row>
                </div>
              </div>
            </template>
          </vx-card>
        </vs-list>
      </div>
    </VuePerfectScrollbar>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
import draggable from "vuedraggable";
import ModalCadastroRota from "./cadastro";
import ModalEditarRota from "./editar";

export default {
  props: { blocked: Boolean, lev: { default: 0 } },
  data() {
    return {
      selected: {},
      modalCadastroRota: false,
      selectedRot: 0,
      cardClass: "rota-card overflow-hidden",
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      novoClienteModal: false,
      rotas: {}
    };
  },

  methods: {
    async selectTec(rota) {
      if (!this.blocked) {
        this.selected.id = rota.id;
        this.selected.nome = rota.nome;

        await this.$emit("update", this.selected);
      }
    },
    async getRotas() {
      await this.$vs.loading({ container: "#rotaLista", scale: 0.6 });
      try {
        this.rotas = await this.$http.get(`rota`);
        this.rotas = this.rotas.filter(rota => rota.levantamento == this.lev);
        await this.$emit("rotaEmit", this.rotas);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close("#rotaLista > .con-vs-loading");
      }
    }
  },
  components: { ...components, draggable, ModalCadastroRota, ModalEditarRota },
  async mounted() {
    await this.getRotas();
  }
};
</script>

<style lang="scss" scoped>
.rota-disabled {
  background-color: #f0f0f0;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  cursor: not-allowed;
  h4,
  h6 {
    color: #7c7c7c;
  }
}
.rota {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  color: #ffca00 !important;
}
.rota:hover {
  transition: 0.1s;
  background: #eeeeee;
  transform: translateY(-3px);
}
.active-rota {
  background-color: #00bba2;
  transition: background 0.1s ease-in-out;
  transition: 0.4s;
  border-radius: 5px;
  font-size: 16px;
}
.rota-disabled {
  background-color: #f0f0f0;
  transition: background 0.1s ease-in-out;
  transition: 0.1s;
  border-radius: 5px;
  font-size: 16px;
  cursor: not-allowed;
  h4,
  h6 {
    color: #7c7c7c;
  }
}
.scroll-rota-lista {
  max-height: 65vh !important;
}
</style>
