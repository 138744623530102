<template>
  <div>
    <EditButton
      :disabled="
        !$atividades.permissoes.includes(14) &&
          !$atividades.permissoes.includes(8) &&
          !$atividades.permissoes.includes(2)
      "
      @func="openModal()"
    ></EditButton>
    <SideBar
      title="Editar rota"
      @hide="cancelar"
      @excluir="excluirRota"
      @status="mudarStatusRota"
      :active="modalEditarRota"
      :item="rota"
      :disabled="
        !$atividades.permissoes.includes(15) &&
          !$atividades.permissoes.includes(9) &&
          !$atividades.permissoes.includes(3)
      "
      size="small"
      textSucces="Atualizar"
      @success="submitForm()"
    >
      <div slot="body">
        <vs-row class="mb-4" vs-type="flex" vs-w="12">
          <vs-col vs-w="12">
            <vs-input
              class="inputx w-full"
              label-placeholder="Nome"
              v-model="rota.nome"
              name="nome"
              v-validate="'required'"
            />
            <span class="text-danger text-sm" v-show="errors.has('nome')">{{ $validators.empty }}</span>
          </vs-col>
          <vs-col vs-w="12" class="mt-5">
            <div class="vx-col w-full">
              <vs-checkbox color="success" v-model="rota.levantamento" class>Levantamento</vs-checkbox>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </SideBar>
  </div>
</template>

<script>
import components from "@/components/default/exports.js";

export default {
  name: "modalEditarRota",
  props: { rota: Object },
  data() {
    return {
      modalEditarRota: false,
      error: false,
      originalData: {}
    };
  },
  methods: {
    async openModal() {
      this._beforeEditingCache = Object.assign({}, this.rota);
      this.originalData = this.rota;
      this.modalEditarRota = await true;
    },
    async cancelar() {
      Object.assign(this.rota, this._beforeEditingCache);
      this.originalData = this._beforeEditingCache = null;
      this.modalEditarRota = await false;
    },
    async submitForm() {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.editarRota();
        } else {
          this.error = true;
          this.$vs.notify(this.$notify.Empty);
        }
      });
    },
    async editarRota() {
      this.$vs.loading();
      try {
        const res = await this.$http.put(`rota/${this.rota.id}`, this.rota);
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Edição da rota N°" + res.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarRota = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
      }
    },
    async excluirRota() {
      this.$vs.loading();
      try {
        await this.$http.delete(`rota/${this.rota.id}`);
        // let logData = {
        //   id_colaborador: localStorage.getItem("id"),
        //   funcao: "excluir",
        //   setor: "comercial",
        //   ip: window.localStorage.getItem("ip"),
        //   texto: "Exclusão da rota N°" + this.rota.id
        // };
        // await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    },
    async mudarStatusRota() {
      this.$vs.loading();
      try {
        await this.$http.put(`rota/${this.rota.id}`, {
          lixeira: !this.rota.lixeira
        });
        let logData = {
          id_colaborador: localStorage.getItem("id"),
          funcao: "atualizar",
          setor: "comercial",
          ip: window.localStorage.getItem("ip"),
          texto: "Mudança do status da rota N°" + this.rota.id
        };
        await this.$logger(logData);
        this.$vs.notify(this.$notify.Success);
        this.modalEditarFornecedor = false;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.$vs.loading.close();
        this.$emit("update");
      }
    }
  },
  components: components
};
</script>

<style></style>
