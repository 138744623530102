<template>
  <div>
    <vx-card :class="cardClass">
      <template slot="no-body">
        <div class="item-details px-2">
          <div class="my-1">
            <vs-col
              v-if="item.nome_fantasia"
              class="pb-1"
              vs-w="10"
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="flex-start"
            >
              <ModalVisualizarCliente :cliente="cliente" textColor="dark" />
            </vs-col>
            <vs-col
              vs-w="2"
              vs-type="flex"
              vs-justify="flex-end"
              vs-align="flex-end"
              v-if="1 == 1"
            >
              <Badge :text="'HOJE'" color="danger"></Badge>
            </vs-col>
            <div @click="iniciarLevantamentoModal = false">
              <vs-col
                v-if="item.enderecoNome"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm"
                  >Endereco:
                  <span class="font-semibold">{{
                    item.enderecoNome
                  }}</span></span
                >
              </vs-col>
              <vs-col
                v-if="item.id_turno"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm"
                  >Turno:
                  <span class="font-semibold">{{
                    turnos[item.id_turno].nome
                  }}</span></span
                >
              </vs-col>
              <vs-col
                v-if="item.segmento"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span class="item-description truncate text-sm"
                  >Segmento:
                  <span class="font-semibold">{{ item.segmento }}</span></span
                >
              </vs-col>
              <vs-col
                v-if="item.data_competencia"
                vs-w="12"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="flex-start"
              >
                <span
                  class="item-description truncate text-sm"
                  style="font-size: 20px !important;"
                  >Data:
                  <span class="font-semibold">{{
                    item.data_competencia | moment("DD/MM/YYYY")
                  }}</span></span
                >
              </vs-col>
            </div>
          </div>
        </div>
      </template>
    </vx-card>
  </div>
</template>
<script>
import components from "@/components/default/exports.js";
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    frasesDeclinio: {}
  },
  data() {
    return {
      cliente: {},
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" }
      ],
      cardClass: "prospeccao-card overflow-hidden",
      optionEmpresa: [
        { text: "EMOPS", value: 4 },
        { text: "E-CONTROL", value: 1 }
      ],
      selectedEmpresa: 1,
      iniciarLevantamentoModal: false
    };
  },
  methods: {
    async getCliente() {
      try {
        this.cliente = await this.$http.get(`cliente/` + this.item.id);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    }
  },
  components: { ...components },
  async mounted() {
    await this.getCliente();
  }
};
</script>

<style lang="scss" scoped>
.prospeccao-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccao-card-danger {
  transition-duration: 50ms;
  background-color: #c0564b;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospeccao-card-orange {
  transition-duration: 50ms;
  background-color: orange;
  color: white;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.prospectar {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.cardCliente {
  background-color: #fbfbfb;
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: white;
    cursor: pointer;
    .grid-view-img {
      opacity: 0.9;
    }
  }
}
</style>
