<template>
  <div>
    <div>
      <vs-row vs-type="flex" class="mb-4" vs-justify="center" vs-align="center" vs-w="12">
        <vs-col vs-w="4" vs-justify="flex-start" vs-align="flex-start">
          <h4 style="color: #B4AA99">Associação e sincronizacao de levantamentos.</h4>
        </vs-col>
        <vs-col size="small" vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="8">
          <vs-input class="mx-2" type="date" size="small" label="Data" v-model="filtro.data"></vs-input>
          <div class="mx-2">
            <label style="color: black; font-size: .85rem;">Turnos</label>
            <br />
            <el-select filterable clearable size="small" v-model="filtro.turno">
              <el-option
                v-for="turno in turnos"
                class="select-info"
                :value="turno.id"
                :label="turno.nome"
                :key="turno.id"
              ></el-option>
            </el-select>
          </div>
          <div class="mx-2">
            <vs-input
              label="Hora marcada"
              name="hora_marcada"
              v-model="filtro.horario"
              v-mask="'##:00'"
              max="24"
              size="small"
              type="datetime"
              placeholder="00:00"
              class="inputx w-full"
            />
          </div>
          <el-button
            class="mx-2"
            :disabled="blockButton"
            plain
            @click="filtrar()"
            type="primary"
            size="small"
          >Filtrar</el-button>
        </vs-col>
      </vs-row>
    </div>
    <vs-row vs-type="flex" vs-justify="space-between">
      <vs-col vs-w="3" class="px-2">
        <rotaLista
          lev="1"
          @update="selectTec"
          :blocked="
            JSON.stringify(levantamentosTec) !==
              JSON.stringify(levantamentosTecOriginal)
          "
        />
      </vs-col>
      <vs-col vs-w="5" class="px-2">
        <div>
          <vx-card style="background-color: #000a12 ;">
            <vs-row vs-type="flex">
              <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <div>
                  <h5 style="color: white">LEVANTAMENTOS PENDENTES: {{ levantamentos.length }}</h5>
                </div>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-rota-assoc"
            :settings="settings"
            style="background-color: #ebebeb;"
          >
            <div id="levantamento_lista" class="vs-con-loading__container">
              <div v-if="!levantamentos.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="color: grey !important; font-weight: 1000 !important"
                    >
                      <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <draggable
                :disabled="!$atividades.permissoes.includes(2)"
                v-model="levantamentos"
                group="lev"
              >
                <div v-for="data in levantamentos" :key="data.id" class="mx-2">
                  <vx-card :class="cardClass" class="mr-10 my-3">
                    <template slot="no-body">
                      <div class="item-details px-2">
                        <div class="my-1">
                          <div class="w-full">
                            <vs-col vs-w="8">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-lg">
                                  Data:
                                  <b>
                                    {{
                                    data.remarcado > 0
                                    ? data.data_remarcacao
                                    : data.data_competencia
                                    | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-lg">
                                  Bairro:
                                  <b>{{ data.nomeBairro }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <VisualizarCliente
                                  @update="filtrar()"
                                  :id_cliente="data.id_cliente"
                                  :nomeCliente="data.nomeCliente"
                                />
                              </vs-col>
                              <div class="w-full">
                                <vs-col
                                  vs-w="12"
                                  class="mt-1"
                                  vs-type="flex"
                                  vs-justify="flex-start"
                                  vs-align="flex-start"
                                >
                                  <span class="item-description truncate text-md">
                                    Levantamento N°
                                    <b>{{ data.id }}</b>
                                  </span>
                                </vs-col>
                              </div>
                            </vs-col>
                            <vs-col
                              vs-w="4"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <div class="w-full">
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <vs-col
                                    vs-w="12"
                                    vs-type="flex"
                                    vs-justify="flex-end"
                                    vs-align="flex-end"
                                  >
                                    <Badge
                                      v-if="data.hora_marcada"
                                      :text="data.hora_marcada"
                                      color="warning"
                                    ></Badge>
                                    <Badge
                                      v-else
                                      :text="turnos[data.id_turno].nome"
                                      color="warning"
                                    ></Badge>
                                  </vs-col>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge v-if="data.escada > 0" :text="'Escada'" color="danger"></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge v-if="data.fog > 0" :text="'Fog'" color="danger"></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge v-if="data.nomeZona" :text="data.nomeZona" color="success"></Badge>
                                </vs-col>
                              </div>
                            </vs-col>
                          </div>
                        </div>
                      </div>
                    </template>
                  </vx-card>
                </div>
              </draggable>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>

      <vs-col vs-w="4" class="px-2">
        <div>
          <vx-card card-background="#021a33" :key="reRender">
            <vs-row vs-type="flex">
              <vs-col vs-w="9" vs-type="flex" vs-justify="flex-start" vs-align="flex-start">
                <div>
                  <h5 style="color: white">
                    {{
                    selectedTec.nome == null
                    ? "Selecione..."
                    : selectedTec.nome + ": " + levantamentosTec.length
                    }}
                  </h5>
                </div>
              </vs-col>
              <vs-col vs-w="3" vs-type="flex" vs-justify="flex-end" vs-align="flex-end">
                <vs-button
                  :disabled="
                    !$atividades.permissoes.includes(2) ||
                      JSON.stringify(levantamentosTec) ==
                        JSON.stringify(levantamentosTecOriginal)
                  "
                  color="success"
                  size="small"
                  @click="associarRota()"
                >ASSOCIAR</vs-button>
              </vs-col>
            </vs-row>
          </vx-card>
          <VuePerfectScrollbar
            class="scroll-rota-assoc"
            :settings="settings"
            style="background-color: #ebebeb;"
          >
            <div id="levantamento_tec_lista" class="vs-con-loading__container">
              <div v-if="!levantamentosTec.length > 0">
                <div class="con-colors">
                  <ul>
                    <span
                      class="px-3 pt-2"
                      style="color: grey !important; font-weight: 1000 !important"
                    >
                      <vs-icon size="large" icon="sentiment_dissatisfied"></vs-icon>
                    </span>
                  </ul>
                </div>
              </div>
              <draggable
                :disabled="!$atividades.permissoes.includes(2)"
                v-if="selectedTec.nome != null"
                v-model="levantamentosTec"
                group="lev"
              >
                <div v-for="data in levantamentosTec" :key="data.id" class="mx-2">
                  <vx-card :class="cardClass" class="mr-10 my-3">
                    <template slot="no-body">
                      <div class="item-details px-2">
                        <div class="my-1">
                          <div class="w-full">
                            <vs-col vs-w="8">
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-lg">
                                  Data:
                                  <b>
                                    {{
                                    data.remarcado > 0
                                    ? data.data_remarcacao
                                    : data.data_competencia
                                    | moment("DD/MM/YYYY")
                                    }}
                                  </b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <span class="item-description truncate text-lg">
                                  Bairro:
                                  <b>{{ data.nomeBairro }}</b>
                                </span>
                              </vs-col>
                              <vs-col
                                vs-w="12"
                                class="mt-1"
                                vs-type="flex"
                                vs-justify="flex-start"
                                vs-align="flex-start"
                              >
                                <VisualizarCliente
                                  @update="filtrar()"
                                  :id_cliente="data.id_cliente"
                                  :nomeCliente="data.nomeCliente"
                                />
                              </vs-col>
                              <div class="w-full">
                                <vs-col
                                  vs-w="12"
                                  class="mt-1"
                                  vs-type="flex"
                                  vs-justify="flex-start"
                                  vs-align="flex-start"
                                >
                                  <span class="item-description truncate text-md">
                                    Levantamento N°
                                    <b>{{ data.id }}</b>
                                  </span>
                                </vs-col>
                              </div>
                            </vs-col>
                            <vs-col
                              vs-w="4"
                              vs-type="flex"
                              vs-justify="flex-end"
                              vs-align="flex-end"
                            >
                              <div class="w-full">
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <vs-col
                                    vs-w="12"
                                    vs-type="flex"
                                    vs-justify="flex-end"
                                    vs-align="flex-end"
                                  >
                                    <Badge
                                      v-if="data.hora_marcada"
                                      :text="data.hora_marcada"
                                      color="warning"
                                    ></Badge>
                                    <Badge
                                      v-else
                                      :text="turnos[data.id_turno].nome"
                                      color="warning"
                                    ></Badge>
                                  </vs-col>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge v-if="data.escada > 0" :text="'Escada'" color="danger"></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge v-if="data.fog > 0" :text="'Fog'" color="danger"></Badge>
                                </vs-col>
                                <vs-col
                                  vs-w="12"
                                  vs-type="flex"
                                  vs-justify="flex-end"
                                  vs-align="flex-end"
                                >
                                  <Badge v-if="data.nomeZona" :text="data.nomeZona" color="success"></Badge>
                                </vs-col>
                              </div>
                            </vs-col>
                          </div>
                        </div>
                      </div>
                    </template>
                  </vx-card>
                </div>
              </draggable>
            </div>
          </VuePerfectScrollbar>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import levantamentoLista from "./components/levantamentoLista.vue";
import rotaLista from "./components/rotaLista.vue";
import draggable from "vuedraggable";
import components from "@/components/default/exports.js";
import VisualizarCliente from "./../cliente/visualizar.vue";

export default {
  data() {
    return {
      cliente: {},
      filtro: {
        dataHoje: this.$formartData.dataFiltro(),
        data: null,
        turno: null
      },
      turnos: [
        { id: null, nome: "Nenhum" },
        { id: 1, nome: "Manha" },
        { id: 2, nome: "Almoco" },
        { id: 3, nome: "Tarde" },
        { id: 4, nome: "Noite" },
        { id: 5, nome: "Madrugada" }
      ],
      cardClass: "os-card overflow-hidden",

      selectedTec: {},
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.8
      },
      atividades: [10, 18],
      levantamentos: [],
      levantamentosTec: [],
      levantamentosTecOriginal: [],
      reRender: 1,
      blockButton: false
    };
  },
  async mounted() {
    await this.getLevantamentos();
  },
  methods: {
    async salvarOrdem() {
      for (let i in this.levantamentosTec) {
        this.levantamentosTec[i].ordem = i;
      }
    },
    async associarRota() {
      let levantamentosTec = this.levantamentosTec;
      let id = this.selectedTec.id;
      await this.$vs.loading({
        container: "#levantamento_tec_lista",
        scale: 0.6
      });
      await this.$vs.loading({ container: "#levantamento_lista", scale: 0.6 });
      try {
        await this.salvarOrdem();
        await this.$http.post("associarRota", { levantamentosTec, id, ip: window.localStorage.getItem("ip") });
        this.levantamentosTecOriginal = this.levantamentosTec;
        this.$vs.notify(this.$notify.Success);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#levantamento_tec_lista > .con-vs-loading"
        );
        await this.$vs.loading.close("#levantamento_lista > .con-vs-loading");
      }
    },
    async selectTec(tecnico) {
      this.selectedTec.id = await tecnico.id;
      this.selectedTec.nome = await tecnico.nome;
      await this.getLevantamentoByTec();
      this.reRender = (await this.reRender) + 1;
    },
    async getLevantamentoByTec() {
      await this.$vs.loading({
        container: "#levantamento_tec_lista",
        scale: 0.6
      });
      try {
        this.levantamentosTec = await this.$http.post(
          `getLevByTec/` + this.selectedTec.id,
          this.filtro
        );
        this.levantamentosTecOriginal = this.levantamentosTec;
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        await this.$vs.loading.close(
          "#levantamento_tec_lista > .con-vs-loading"
        );
      }
    },
    async getLevantamentos() {
      await this.$vs.loading({ container: "#levantamento_lista", scale: 0.6 });
      try {
        this.levantamentos = await this.$http.post(`getLevSync`, this.filtro);
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
      await this.$vs.loading.close("#levantamento_lista > .con-vs-loading");
    },
    async filtrar() {
      this.$vs.loading();
      this.blockButton = true;
      try {
        await this.getLevantamentos();
        if (this.selectedTec.id != null) {
          await this.getLevantamentoByTec();
        }
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      } finally {
        this.blockButton = false;
        this.$vs.loading.close();
      }
    }
  },
  components: {
    ...components,
    levantamentoLista,
    rotaLista,
    draggable,
    VisualizarCliente
  }
};
</script>
<style scoped lang="scss">
.list-item {
  background-color: #fff;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
}
.list-item:hover {
  background: #dcdcdc;
}
.active-list-item {
  background-color: #000a12;
  transition: background 0.1s ease-in-out;
  border-radius: 3px;
  font-size: 16px;
  color: #ffca00;
}
.os-card {
  transition-duration: 50ms;
  &:hover {
    transition-duration: 150ms;
    transform: translateY(-3px);
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.25);
    background-color: #eeeeee;
    color: black;
    cursor: pointer;

    .grid-view-img {
      opacity: 0.9;
    }
  }
}
.scroll-rota-assoc {
  height: 70vh !important;
}
</style>
